.container {
  margin: 8px;
}

.lineChart {
  display: none;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .container {
    display: flex;
    flex-direction: column;
    margin-left: 210px;
    margin-right: 30px;
    margin-top: 10px;
  }
  .lineChart {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .container {
    display: flex;
    flex-direction: column;
    margin-left: 210px;
    margin-right: 30px;
    margin-top: 10px;
  }
  .lineChart {
    display: block;
  }
}
