.div {
  background-color: #081a2b;
  padding: 10px;
}
.minMenu {
  width: 100%;
  margin-left: 0.4em;
  margin-top: 1em;
  color: white;
  font-weight: bold;
  font-size: 12px;
  opacity: 0.5;
}
.ul {
  list-style-type: none;
  margin: 1px;
}
.li {
  margin: 1px;
  padding: 0.5em;
  border: 1px #1555c3;
}
a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .minMenu {
    width: 100%;
    margin-left: 4em;
    margin-top: 1em;
    color: white;
    font-weight: bold;
    font-size: 12px;
    opacity: 0.5;
  }

  .div {
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #081a2b;
    overflow-x: hidden;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
  }

  .ul {
    list-style-type: none;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
  }

  .li {
    margin: 5px;
    width: 90%;

    padding: 1em;
    border: 9px #1555c3;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
  }
  a:hover {
    color: white;
  }

  .li:hover {
    cursor: pointer;
    background-color: #831b1e;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .minMenu {
    width: 100%;
    margin-left: 4em;
    margin-top: 1em;
    color: white;
    font-weight: bold;
    font-size: 12px;
    opacity: 0.5;
  }

  .div {
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #081a2b;
    overflow-x: hidden;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
  }

  .ul {
    list-style-type: none;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
  }

  .li {
    margin: 5px;
    width: 90%;

    padding: 1em;
    border: 9px #1555c3;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
  }
  a:hover {
    color: white;
  }

  .li:hover {
    cursor: pointer;
    background-color: #831b1e;
  }
}
